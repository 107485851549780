<template>
    <v-app>
        <div v-if="$router.currentRoute.path == '/' || $router.currentRoute.path == '/forgot-password' || $router.currentRoute.path == '/change-password'
            || $router.currentRoute.name == '404'">
            <v-main>
                <router-view></router-view>
            </v-main>
        </div>
        <div v-else>
            <v-app-bar app dark dense flat v-if="$router.currentRoute.path != '/sign-in'">
                <v-app-bar-nav-icon @click="$root.$emit('drawer')"></v-app-bar-nav-icon>
                <v-toolbar-title>
                    <router-link to="/invoices" class="d-flex align-center link text-h6 font-weight-bold white--text">INVOICES</router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn depressed color="primary" class="mr-2 text-none" @click="chngPwdDialog = true">Cambiar contraseña</v-btn>
                <v-btn depressed color="error" class="text-none" @click="logout">Salir</v-btn>
            </v-app-bar>
            <v-main class="bg-gray-100">
                <v-container>
                    <v-row class="justify-center">
                        <v-col class="bg-white h-screen pt-5" sm="8">
                            <router-view/>

                            <v-dialog v-model="chngPwdDialog" width="500" persistent>
                                <v-card>
                                    <v-card-title>Cambiar contraseña</v-card-title>
                                    <v-card-subtitle>Complete la información para actualizar su contraseña</v-card-subtitle>
                                    <v-card-text>
                                        <div>
                                            La nueva contraseña debe contener:
                                            <ul style="list-style: initial;">
                                                <li>Al menos 8 caracteres</li>
                                                <li>Letras mayúsculas y minúsculas</li>
                                                <li>Al menos un número</li>
                                            </ul>
                                        </div>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-form ref="form">
                                            <v-text-field v-model="currentPwd" placeholder="Contraseña actual" prepend-inner-icon="mdi-lock-outline" outlined dense type="password"
                                                :rules="[rules.required]"></v-text-field>
                                            <v-text-field v-model="newPwd" placeholder="Contraseña nueva" prepend-inner-icon="mdi-lock-outline" outlined dense type="password"
                                                :rules="[rules.required, rules.minLength, rules.numbers, rules.uppers, rules.lowers]"></v-text-field>
                                            <v-text-field v-model="confirmation" placeholder="Confirmar contraseña" prepend-inner-icon="mdi-lock-outline" outlined dense type="password"
                                                :rules="[rules.required, rules.match]" @keypress.enter.prevent="change"></v-text-field>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn depressed text class="text-none" @click="chngPwdDialog = false">Cancelar</v-btn>
                                        <v-btn depressed color="primary" class="text-none" :loading="loading" @click="change">Cambiar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
            <sidebar></sidebar>
            <snackbar></snackbar>
        </div>
    </v-app>
</template>

<script>
import Sidebar from '@/components/Sidebar';
import Snackbar from '@/components/Snackbar';
import { mapActions } from 'vuex';

export default {
    name: 'App',
    components: { Sidebar, Snackbar },
    data() {
        return {
            chngPwdDialog   : false,

            currentPwd      : '',
            newPwd          : '',
            confirmation    : '',

            loading         : false,

            rules           : {
                required    : v => !!v || 'este campo es requerido',
                minLength   : v => v.length >= 8 || 'debe tener al menos 8 caracteres',
                numbers     : v => /[0-9]+/g.test( v ) || 'debe tener al menos un número',
                uppers      : v => /[A-Z]+/g.test( v ) || 'debe tener al menos una letra mayúscula',
                lowers      : v => /[a-z]+/g.test( v ) || 'debe tener al menos una letra minúscula',

                match       : v => v === this.newPwd || 'la confirmación no es igual al password'
            }
        }
    },
	async created() {
		this.$axios.interceptors.response.use((response) => {
            console.log(response);
			const { status = 'success', message } = response.data
			if (message) {
				this.$root.$emit('snackbar', { status, message })
			}

			return Promise.resolve(response)
		}, (err) => {
            if( err.response.status === 401 ) {
                this.logout();
                return;
            }

			const message = (err.response && err.response.data && err.response.data.message)
			? err.response.data.message 
			: 'Internal server error'

			this.$root.$emit('snackbar', { status: 'error', message })
			return Promise.reject(err)
		});
	},
    methods: {
        ...mapActions({
            signout     : 'logout',
            changePwd   : 'changePassword'
        }),
        logout() {
            this.signout();
        },
        async change() {
            if( !this.$refs.form.validate() )
                return;

            this.loading = true;
            let res = await this.changePwd({
                oldPassword : this.currentPwd,
                newPassword : this.newPwd
            });
            this.loading = false;

            if( res.data.status === true ) {
                this.chngPwdDialog = false;
                this.$refs.form.reset();
            }
        }
    }
};
</script>
