<template>
    <v-navigation-drawer v-model="drawer" fixed temporary>
        <v-list-item>
        <v-list-item-content>
            <v-list-item-title class="text-h6">Invoices</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
      <v-divider></v-divider>
        <v-list nav dense>
            <v-list-item-group v-model="route">
                <v-list-item v-for="({ title, to, icon }, i) in routes" :key="`route-${i}`" :to="to" color="primary">
                    <v-list-item-action>
                        <v-icon v-text="icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="title" class="text-uppercase"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>
<script>

export default {
	name: 'Sidebar',
	data: () => ({
        drawer: false,
		route: -1,
		routes: [
			{ title: 'Invoices', to: '/invoices', icon: 'mdi-file' },
			{ title: 'Create Invoice', to: '/create', icon: 'mdi-file-plus' },
		]
	}),
    mounted() {
        this.$root.$on('drawer', () => this.drawer = !this.drawer)
    }
}
</script>