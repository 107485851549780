import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import axios from 'axios';
import { _st } from '@/softech';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId      : null,
    user        : null,
    token       : null
  },
  mutations: {
    setUserId( state, payload ) {
        state.userId = payload;
      },
      setUser( state, payload ) {
        state.user = payload;
      },
      setToken( state, payload ) {
        state.token = payload;
      }
  },
  actions: {
    logout({ commit }) {
      try {
        commit('setUserId', null);
        commit('setUser', null);
        commit('setToken', null);

        localStorage.removeItem('auth');

        router.push('/').catch((err) => {
          if( err.name != 'NavigationDuplicated') throw err;
        });
        return Promise.resolve();
      }
      catch(error) {
        console.log(error);
        return Promise.reject();
      }
    },
    async login(_, { username, password }) {
      try {
        let res = await axios.post('/signin', { username, password });

        if( res.data.status === true ) {
          localStorage.setItem('auth', res.data.token);
          if( _st.isNUE( res.data.nextStep ) ) {
            router.push('/invoices');
            // bus.$emit('login');
          }
          else if( res.data.nextStep == 'change' ) {
            router.push('/change-password');
          }
        }

        return Promise.resolve( res.data );
      }
      catch( error ) {
          console.log( error );
          return Promise.reject( error );
      }
    },
    async changePassword(_, { oldPassword, newPassword }) {
      try {
        let res = await axios.post('/change-password', { 
          current : oldPassword, 
          password: newPassword 
        });
        
        return Promise.resolve( res );
      } 
      catch(error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async resetPassword({ commit, state }, password ) {
      try {
        let res = await axios.post('/reset-password', { password });

        if( res.data.status === true ) {
          let res2 = await axios.get( `/user/${state.user.id}` );

          if( res2.data.status === true ) {
            commit( 'setUser', res2.data.data );
          }

          router.push('/invoices');
          // bus.$emit('login');
        }

        return Promise.resolve( res.data );
      }
      catch( error ) {
        console.log( error );
        return Promise.reject( error );
      }
    },
    async forgotPassword(_, email) {
      try {
        let res = await axios.post('/forgot-password', { email });
        
        return Promise.resolve( res.data );
      } 
      catch(error) {
        return Promise.reject(error);
      }
    },
    async authAction({ commit, dispatch, state }) {
      let token = localStorage.getItem('auth');
      if( _st.isNUE( token ) ) {
        return Promise.resolve();
      }

      var jwt = require('jsonwebtoken');
      let decoded = jwt.decode( token, {complete: true} );

      commit('setUserId', decoded.payload.usr);
      commit('setToken', token);

      axios.defaults.headers.common['Authorization'] = token;

      if( _st.isNUE( state.user ) || state.user.id != state.userId ) {
        try {
          let res = await axios.get( `/user/${decoded.payload.usr}` );

          console.log(res);

          if( res.data.status === true ) {
            commit( 'setUser', res.data.data );
          }

          return Promise.resolve();
        }
        catch(error) {
          if( error.response.status === 401 ) {
            dispatch('logout');
            return Promise.resolve();
          }

          return Promise.reject(error);
        }
      }
    }
  },
  getters: {
    user                : (state) => state.user,
		isLoggedIn          : (state) => !_st.isNUE( state.token ),
    token               : (state) => state.token
  },
  modules: {
  }
})
