import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [{
        path: '/invoices',
        name: 'invoices-index',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "invoices-index" */ '@/views/Invoices/Index.vue')
    }, {
        path: '/create',
        name: 'invoices-create',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "invoices-create" */ '@/views/Invoices/Create.vue')
    }, {
        path: '/edit/:id',
        name: 'invoices-edit',
        props: true,
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "invoices-edit" */ '@/views/Invoices/Create.vue')
    }, {
        path: '/:id(\\d+)',
        name: 'invoices-detail',
        props: true,
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "invoices-detail" */ '@/views/Invoices/Detail.vue')
    }, {
        path: '/',
        name: 'sign-in',
        props: false,
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "sign-in" */ '@/views/SignIn.vue')
    }, {
        path: '/forgot-password',
        name: 'forgot-password',
        props: false,
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "sign-in" */ '@/views/ForgotPassword.vue')
    }, {
        path: '/change-password',
        name: 'change-password',
        props: false,
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "sign-in" */ '@/views/ChangePassword.vue')
    }, {
        path: '*',
        name: '404',
        props: false,
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "sign-in" */ '@/views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {  
    await store.dispatch('authAction');

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const isLoggedIn = store.getters['isLoggedIn'];
    const user = store.getters['user'];

    console.log(to, requiresAuth, isLoggedIn, user);

    if( requiresAuth && !isLoggedIn ) {
        next('/');
        return;
    } 
    else if( user != null && user.resetPassword === true && to.path != '/change-password' ) {
        next('/change-password');
        return;
    }
    else if( user != null && user.resetPassword !== true && to.path == '/change-password' ) {
        next('/invoices');
    }
    
    next();
  })

export default router
